import React, { useState, useImperativeHandle } from 'react';
import { evaluate, parse } from 'mathjs';
import { useTranslation } from '../TranslationContext';
import { Capitalize } from '../Util';

interface CalculatorPart {
    [key: string]: number;
}

interface CalculatorPartDetail {
    label: string;
    unit: string;
    value: number;
}

interface CalculatorResult {
    symbol: string;
    label: string;
    unit: string;
}

interface CalculatorFormula {
    formula: string;
    parts: { [key: string]: CalculatorPartDetail };
    result: CalculatorResult;
}

interface CalculatorData {
    name: string;
    formulas: {
        [key: string]: CalculatorFormula;
    };
}

interface GenericCalculatorProps {
    calculatorData: CalculatorData;
    onCalculate: (inputs: { [key: string]: number }) => { [key: string]: number };
    onCalculationComplete: (result: any) => any | null;
}

const GenericCalculator = React.forwardRef((props: GenericCalculatorProps, ref) => {
    const { t } = useTranslation();

    // Select the first formula as the default
    const firstFormulaKey = Object.keys(props.calculatorData.formulas)[0];
    const selectedFormula = props.calculatorData.formulas[firstFormulaKey];

    // Initialize state with default values from the selected formula
    const defaultValues = Object.fromEntries(
        Object.entries(selectedFormula.parts)
            .map(([key, detail]) => [key, detail.value])
    );
    const [inputs, setInputs] = useState<CalculatorPart>(defaultValues);
    const [result, setResult] = useState<string | number | null>(null);


    const handleInputChange = (part: string, value: string) => {
        const valueAsNumber = parseFloat(value);
        setInputs({
            ...inputs,
            [part]: isNaN(valueAsNumber) ? 0 : valueAsNumber,
        });
    };

    const calculateResult = (formula: string, parts: { [key: string]: CalculatorPartDetail }, inputs: { [key: string]: number }) => {
        try {
            const scope: CalculatorPart = {};
            Object.keys(parts).forEach(part => {
                scope[part] = parse(inputs[part]?.toString() || '0').compile().evaluate();
            });
            const result = evaluate(formula, scope);
            if (isNaN(result)) {
                console.error('Error in calculation:', result);
                setResult('One or more values is invalid');
            } else {
                let finalResult = result;
                if (props.onCalculationComplete) {
                    finalResult = props.onCalculationComplete(result);
                }
                setResult(finalResult);
            }
        } catch (error) {
            console.error('Error in calculation:', error);
            setResult('Invalid input');
        }
    };

    const performCalculation = () => {
        const firstFormulaKey = Object.keys(props.calculatorData.formulas)[0];
        const { formula, parts } = props.calculatorData.formulas[firstFormulaKey];
        const newInputs = props.onCalculate(inputs);
        calculateResult(formula, parts, newInputs);
    };

    useImperativeHandle(ref, () => ({
        performCalculation,
    }));

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        performCalculation();
    };

    return (
        <div className="container mt-4">
            <h2 className="mb-4">{props.calculatorData.name}</h2>
            <form onSubmit={handleSubmit}>
                <div className="row">
                    {Object.entries(props.calculatorData.formulas.calculate_final_amount.parts).map(([part, detail]) => (
                        <div key={part} className="col-md-6 col-lg-3 mb-3">
                            <label htmlFor={part} className="form-label">{detail.label}:</label>
                            <div className="input-group">
                                <input
                                    type="number"
                                    className="form-control"
                                    id={part}
                                    value={inputs[part]?.toString() || ''}
                                    onChange={(e) => handleInputChange(part, e.target.value)}
                                />
                                <div className="input-group-append">
                                    <span className="input-group-text">{detail.unit}</span>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="row">
                    <button type="submit" className="btn btn-success">{Capitalize(t('conversion.calculate'))}</button>
                </div>
            </form>
            {result !== null && <p className="mt-3">{selectedFormula.result.label}: {result} {selectedFormula.result.unit}</p>}
        </div>
    );
});

export default GenericCalculator;
