import React, { useState, useEffect, useRef } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, ChartOptions, CategoryScale, LinearScale, BarElement, Title, Tooltip, TooltipItem, Legend } from 'chart.js';
import GenericCalculator from './GenericCalculator';
import { MathJax, MathJaxContext } from 'better-react-mathjax';
import { useTranslation } from '../TranslationContext';
import { Capitalize } from '../Util';
import { useBreadcrumb } from './BreadcrumbContext';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface YearlyData {
    year: number;
    principalGrown: number;
    interestOnContributions: number;
}

interface GenericCalculatorMethods {
    performCalculation: () => void;
}

const CompoundInterestCalculator: React.FC = () => {
    const { t } = useTranslation();
    const { setBreadcrumb } = useBreadcrumb();
    const genericCalculatorRef = useRef<GenericCalculatorMethods>(null);

    const compoundCalculatorData = {
        name: "",
        formulas: {
            calculate_final_amount: {
                formula: "P * (1 + r/n)^(n*t) + C * (((1 + r/n)^(n*t) - 1) / (r/n))",
                parts: {
                    "P": {
                        "label": t('compound_interest_calculator.terms.p.name'),
                        "unit": "€",
                        "value": 0
                    },
                    "C": {
                        "label": t('compound_interest_calculator.terms.c.name'),
                        "unit": "€",
                        "value": 1200
                    },
                    "r": {
                        "label": t('compound_interest_calculator.terms.r.name'),
                        "unit": "%",
                        "value": 7
                    },
                    "t": {
                        "label": t('compound_interest_calculator.terms.t.name'),
                        "unit": t('conversion.years'),
                        "value": 10
                    },
                    "n": {
                        "label": t('compound_interest_calculator.terms.n.name'),
                        "unit": t('compound_interest_calculator.times'),
                        "value": 1
                    }
                },
                result: {
                    "symbol": "A",
                    "label": t('compound_interest_calculator.terms.a.name'),
                    "unit": "€"
                }
            }
        }
    };

    useEffect(() => {
        setBreadcrumb([
            { text: Capitalize(t(`page.index.name`)), path: '/' },
            { text: Capitalize(t(`conversion.personal_finance`)), path: `/${t(`conversion.calculate`)}/${t(`category.personal_finance`)}` },
            { text: Capitalize(t('compound_interest_calculator.title')) },
        ]);
        // Automatically invoke the calculation when the component mounts
        if (genericCalculatorRef.current) {
            genericCalculatorRef.current.performCalculation();
        }
    }, []);

    const [yearlyData, setYearlyData] = useState<YearlyData[]>([]);

    const handleCalculationComplete = (result: any) => {
        return Math.round(result);
    };

    const calculateCompoundInterest = (inputs: { [key: string]: number }) => {
        const { P, C, r, t, n } = inputs;
        let data: YearlyData[] = [];
        let totalContributions = 0;

        for (let year = 1; year <= t; year++) {
            // Total principal amount at the end of each year
            let totalPrincipal = P + (C * year);

            // Total amount (principal + interest)
            let totalAmount = P * Math.pow((1 + r / 100 / n), n * year) +
                C * (((Math.pow((1 + r / 100 / n), n * year) - 1) / (r / 100 / n)));

            // Interest earned is the difference between total amount and total principal
            let interestEarned = totalAmount - totalPrincipal;

            data.push({
                year,
                principalGrown: totalPrincipal, // Total principal at the end of each year
                interestOnContributions: interestEarned // Interest earned on contributions
            });
        }
        setYearlyData(data);

        const newInputs = Object.assign({}, inputs);
        const newInterestRate = inputs['r'] / 100;
        newInputs['r'] = newInterestRate;
        return newInputs;
    };

    const mathJaxConfig = {
        loader: { load: ['input/asciimath', 'output/chtml'] },
        asciimath2jax: {
            useMathMLspacing: true,
            delimiters: [["$$", "$$"]],
            preview: "none",
        }
    };

    const chartConfig: ChartOptions<"bar"> = {
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
                ticks: {
                    // Custom callback for tick formatting
                    callback: (value) => {
                        return value.toLocaleString('fi-FI').replace(/,/g, ' ') + ' €'; // Replace commas with spaces
                    }
                }
            }
        },
        plugins: {
            tooltip: {
                mode: 'index',
                callbacks: {
                    label: (context) => {
                        const label = context.dataset.label || '';
                        const value = context.parsed.y;
                        return `${label}: ${Math.round(value).toLocaleString('fi-FI').replace(/,/g, ' ')} €`;
                    },
                    afterBody: (tooltipItems) => {
                        let total = tooltipItems.reduce((sum, item) => sum + item.parsed.y, 0);
                        return [`${Capitalize(t('conversion.total'))}: ` + Math.round(total).toLocaleString('fi-FI').replace(/,/g, ' ') + ' €'];
                    }
                }
            }
        }
    };

    const chartData = {
        labels: yearlyData.map(item => `${Capitalize(t('conversion.year'))} ${item.year}`),
        datasets: [
            {
                label: `${Capitalize(t('compound_interest_calculator.principal'))}`,
                data: yearlyData.map(item => item.principalGrown),
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
            {
                label: `${Capitalize(t('compound_interest_calculator.interest'))}`,
                data: yearlyData.map(item => item.interestOnContributions),
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            }
        ]
    };

    return (
        <div className="my-3 text-center">
            <div>
                <h2>{Capitalize(t('compound_interest_calculator.title'))}</h2>
                <p>{t('compound_interest_calculator.main_text')}</p>
            </div>
            <div className="d-flex justify-content-center">
                <div className="w-100"> {/* Adjust width as necessary */}
                    <GenericCalculator
                        ref={genericCalculatorRef}
                        calculatorData={compoundCalculatorData}
                        onCalculate={calculateCompoundInterest
                        } onCalculationComplete={handleCalculationComplete}
                    />
                    {yearlyData.length > 0 && <Bar data={chartData} options={chartConfig} />}
                </div>
            </div>
            <div className="my-3">
                <h3>{t('compound_interest_calculator.formula_title')}</h3>
                <p>{t('compound_interest_calculator.formula_description')}</p>
                <MathJaxContext config={mathJaxConfig}>
                    <MathJax>
                        {`$$A = P \\times \\left(1 + \\frac{r}{n}\\right)^{n \\times t} + C \\times \\left(\\frac{\\left(1 + \\frac{r}{n}\\right)^{n \\times t} - 1}{\\frac{r}{n}}\\right)$$`}
                    </MathJax>
                </MathJaxContext>
                <ul style={{ listStyleType: 'none' }}>
                    <li><strong>A ({t('compound_interest_calculator.terms.a.name')}):</strong> {t('compound_interest_calculator.terms.a.text')}</li>
                    <li><strong>P ({t('compound_interest_calculator.terms.p.name')}):</strong> {t('compound_interest_calculator.terms.p.text')}</li>
                    <li><strong>C ({t('compound_interest_calculator.terms.c.name')}):</strong> {t('compound_interest_calculator.terms.c.text')}</li>
                    <li><strong>r ({t('compound_interest_calculator.terms.r.name')}):</strong> {t('compound_interest_calculator.terms.r.text')}</li>
                    <li><strong>n ({t('compound_interest_calculator.terms.n.name')}):</strong> {t('compound_interest_calculator.terms.n.text')}</li>
                    <li><strong>t ({t('compound_interest_calculator.terms.t.name')}):</strong> {t('compound_interest_calculator.terms.t.text')}</li>
                </ul>
            </div>
        </div >
    );

};

export default CompoundInterestCalculator;
