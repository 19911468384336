import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from '../TranslationContext';
import { Capitalize } from '../Util';
import { useBreadcrumb } from './BreadcrumbContext';
import usePageTitle from '../usePageTitle';

const NotFoundPage: React.FC = () => {
    const { t } = useTranslation();
    const { setBreadcrumb } = useBreadcrumb();

    console.warn("Not found")

    usePageTitle(`${t(`page.not_found.page_title`)} - ${t(`brand.domain`)}`);

    useEffect(() => {
        setBreadcrumb([
            { text: `${Capitalize(t(`page.index.name`))}`, path: '/' },
            { text: `${Capitalize(t('page.not_found.title'))}` },
        ]);
    }, []);

    return (
        <div>
            <div className="text-center my-3">
                <h1>{Capitalize(t('page.not_found.title'))}</h1>
                <p>{Capitalize(t('page.not_found.text'))}</p>
                <Link to="/">{Capitalize(t('page.not_found.text_back'))}</Link>
            </div>
        </div>
    );
};

export default NotFoundPage;
