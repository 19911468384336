import React, { useState, useContext, createContext, ReactNode } from 'react';

interface BreadcrumbItem {
    text: string;
    path?: string;
}

interface BreadcrumbContextProps {
    items: BreadcrumbItem[];
    setBreadcrumb: (items: BreadcrumbItem[]) => void;
}

const BreadcrumbContext = createContext<BreadcrumbContextProps | undefined>(undefined);

export const useBreadcrumb = () => {
    const context = useContext(BreadcrumbContext);
    if (!context) {
        throw new Error('useBreadcrumb must be used within a BreadcrumbProvider');
    }
    return context;
};

export const BreadcrumbProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [items, setBreadcrumb] = useState<BreadcrumbItem[]>([]);

    return (
        <BreadcrumbContext.Provider value={{ items, setBreadcrumb }}>
            {children}
        </BreadcrumbContext.Provider>
    );
};
