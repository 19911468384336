import Big from "big.js";

// Set a threshold for when to use exponential notation.
const MIN_THRESHOLD = new Big('1e-6');
const MAX_THRESHOLD = new Big('1e+6');

Big.DP = 30; // Sets the number of decimal places for the results.
Big.RM = 1;  // Sets the rounding mode (1 = round down).

const formatNumber = (number: string): string => {
    let bigNumber = new Big(number);

    // Use exponential notation for very small or very large numbers
    if (bigNumber.abs().lt(MIN_THRESHOLD) || bigNumber.abs().gt(MAX_THRESHOLD)) {
        return bigNumber.toExponential(2);
    } else {
        // For numbers within the threshold range, use fixed notation.
        bigNumber = bigNumber.round(10); // Round to a maximum of 10 decimal places
        let fixedString = bigNumber.toFixed();

        // Trim unnecessary trailing zeros after the decimal point and the decimal point itself if not needed
        fixedString = fixedString.replace(/(\.\d*?[1-9])0+$/, "$1");
        fixedString = fixedString.replace(/\.0+$/, "");

        // Check if number is an integer, if yes, return without decimal places
        if (bigNumber.eq(bigNumber.round(0))) {
            return bigNumber.toFixed(0);
        }

        return fixedString;
    }
};

// Convert two units and return the formatted result with scientific notation if so needed
function ConvertTwoUnits(firstFactor: string, secondFactor: string, multiply: string = "1"): string {
    const firstBig = new Big(firstFactor);
    const secondBig = new Big(secondFactor);
    const multiplyBig = new Big(multiply);
    const result = secondBig.div(firstBig).mul(multiplyBig);
    return formatNumber(result.toString());
}

// Function to format numbers with a dynamic number of decimal places
function formatFullNumber(number: Big): string {
    const LARGE_NUMBER_THRESHOLD = new Big('1e+6');
    const SMALL_NUMBER_THRESHOLD = new Big('1e-6');

    // For very small numbers, use a fixed large number of decimal places
    if (number.abs().lt(SMALL_NUMBER_THRESHOLD)) {
        return number.toFixed(30).replace(/\.?0+$/, '');
    }

    // For numbers that are not very large, use a fixed number of decimal places
    if (number.abs().lt(LARGE_NUMBER_THRESHOLD)) {
        //const integerPartLength = number.round(0, Big.roundDown).toString().length;
        //const decimalPlaces = Math.max(0, MAX_DECIMALS - integerPartLength);
        return number.toFixed(6).replace(/\.?0+$/, '');
    }

    // For very large numbers, no decimal places
    return number.toFixed(1).replace(/\.?0+$/, '').split(".")[0];
}

// Convert two units and return the full result without scientific notation
function ConvertTwoUnitsFull(firstFactor: string, secondFactor: string, multiply: string = "1"): string {
    const firstBig = new Big(firstFactor);
    const secondBig = new Big(secondFactor);
    const conversionResult = secondBig.div(firstBig).mul(new Big(multiply));
    return formatFullNumber(conversionResult);
}

// Function to check if a number is in scientific notation
function IsScientificNotation(number: string): boolean {
    const bigNumber = new Big(number);
    return bigNumber.e >= MAX_THRESHOLD.e || bigNumber.e <= MIN_THRESHOLD.e;
}

function NormalizeNumberString(numberString: string): string {
    // Replace Finnish decimal comma with dot and remove spaces
    return numberString.replace(/,/g, '.').replace(/\s/g, '');
}

function IsValidNumber(numberString: string): boolean {
    const normalized = NormalizeNumberString(numberString);

    // Regular expression to validate numbers (including scientific notation)
    const numberRegex = /^-?\d+(\.\d+)?(e[-+]?\d+)?$/i;

    return numberRegex.test(normalized);
}

function Capitalize(s: string) {
    if (!s || s.length == 0) {
        return s;
    }
    return s[0].toUpperCase() + s.slice(1);
}

export { ConvertTwoUnits, ConvertTwoUnitsFull, IsScientificNotation, NormalizeNumberString, IsValidNumber, Capitalize };
