import React, { useContext, createContext, ReactNode } from 'react';
import fiTranslations from './translations/fi.json';

const TranslationContext = createContext<Translations>(fiTranslations);

export interface Translations {
    [key: string]: string | Translations;
}

export const useTranslation = () => {
    const translations = useContext(TranslationContext);

    const t = (key: string, defaultValue: string | null = null): string => {
        const keys = key.split('.');
        let result: string | Translations = translations;

        for (const k of keys) {
            result = (result as Translations)[k];
            if (result === undefined) {
                if (defaultValue !== null) {
                    return defaultValue;
                }
                console.error(`Translation key "${key}" not found`);
                return `Missing translation: ${key}`;
            }
        }

        return result as string;
    };

    return { t };
};

interface TranslationProviderProps {
    children: ReactNode;
}

export const TranslationProvider: React.FC<TranslationProviderProps> = ({ children }) => {
    return (
        <TranslationContext.Provider value={fiTranslations}>
            {children}
        </TranslationContext.Provider>
    );
};
