import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import UnitConverter from './UnitConverter';
import UnitInfoSection from './UnitInfoSection';
import { UnitsData } from '../types/unitTypes';
import { useTranslation } from '../TranslationContext';
import { Capitalize } from '../Util';
import { useBreadcrumb } from './BreadcrumbContext';
import usePageTitle from '../usePageTitle';

const SpecificUnitConverter: React.FC = () => {
    const [unitsData, setUnitsData] = useState<UnitsData | null>(null);
    const params = useParams<{ category: string; mainUnit: string; targetUnit: string }>();
    const { category } = useParams<{ category: string; mainUnit: string; targetUnit: string }>();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { setBreadcrumb } = useBreadcrumb();

    const sysCategory = params.category ? t(`sys.category.${params.category}`) : null;
    const sysMainUnit = params.mainUnit ? t(`sys.${sysCategory}.${params.mainUnit}`) : null;
    const sysTargetUnit = params.targetUnit ? t(`sys.${sysCategory}.${params.targetUnit}`) : null;
    const baseSymbolTranslations = `symbols.${sysCategory}`;
    const convertText = `${Capitalize(t(`conversion.convert`))} ${t(`${baseSymbolTranslations}.${sysMainUnit}.plural`)} ${t(`${baseSymbolTranslations}.${sysTargetUnit}.to_plural`)}`;

    // Set page title
    usePageTitle(sysCategory ? `${convertText} - ${t(`brand.domain`)}` : 'Loading...');

    // Scroll to top
    window.scrollTo(0, 0);

    // Fetch unit data
    useEffect(() => {
        if (sysCategory) {
            import(`../data/${sysCategory}_units.json`)
                .then((data) => setUnitsData(data.default as UnitsData))
                .catch(() => navigate('/404'));
        }
    }, [sysCategory, navigate]);

    // Set breadcrumbs
    useEffect(() => {
        if (sysCategory) {
            setBreadcrumb([
                { text: Capitalize(t(`page.index.name`)), path: '/' },
                { text: Capitalize(t(`conversion.${sysCategory}`)), path: `/${t(`conversion.convert`)}/${category}` },
                { text: convertText }
            ]);
        }
    }, [sysCategory, category]);

    if (!sysCategory || !sysMainUnit || !sysTargetUnit || !unitsData) {
        return <div>Loading or invalid parameters...</div>;
    }

    if (!unitsData[sysMainUnit] || !unitsData[sysTargetUnit]) {
        navigate('/404');
        return null;
    }

    const otherUnits = Object.keys(unitsData).filter(u => u !== sysMainUnit && u !== sysTargetUnit);

    if (!params.category) {
        return <div>Loading or invalid parameters...</div>;
    }

    return (
        <div>
            <h2 className="text-center my-4">{convertText}</h2>
            <UnitConverter
                category={sysCategory}
                fromUnit={sysMainUnit}
                toUnit={sysTargetUnit}
                factor={parseFloat(unitsData[sysTargetUnit].factor) / parseFloat(unitsData[sysMainUnit].factor)}
            />
            <div className="row">
                <UnitInfoSection sysCategory={sysCategory} sysUnit={sysMainUnit} />
                <UnitInfoSection sysCategory={sysCategory} sysUnit={sysTargetUnit} />
            </div>
            <div className="row">
                <div className="col-12">
                    <h3>{t(`page.converter.related_conversions`)}</h3>
                    {otherUnits.map((otherUnit) => (
                        <div key={otherUnit} className="mb-2">
                            <Link to={`/${t(`conversion.convert`)}/${params.category}/${t(`${baseSymbolTranslations}.${sysMainUnit}.singular`)}/${t(`${baseSymbolTranslations}.${otherUnit}.singular`)}`}>
                                {Capitalize(t(`conversion.convert`))} {t(`${baseSymbolTranslations}.${sysMainUnit}.plural`)} {t(`${baseSymbolTranslations}.${otherUnit}.to_plural`)}
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default SpecificUnitConverter;
