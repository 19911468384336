import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from '../TranslationContext';
import { Capitalize } from '../Util';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import iconMapping from '../iconMapping'; // Adjust the path as necessary

interface UnitPairSelectorProps {
    unitsData: { [key: string]: any };
    category: string;
}

const UnitPairSelector: React.FC<UnitPairSelectorProps> = ({ unitsData, category }) => {
    const [baseUnit, setBaseUnit] = useState(Object.keys(unitsData)[0]);
    const [targetUnit, setTargetUnit] = useState(Object.keys(unitsData)[1]);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleConversionNavigation = () => {
        if (baseUnit && targetUnit) {
            const baseUnitTranslated = t(`symbols.${category}.${baseUnit}.singular`);
            const targetUnitTranslated = t(`symbols.${category}.${targetUnit}.singular`);
            navigate(`${baseUnitTranslated}/${targetUnitTranslated}`);
        }
    };

    const swapUnits = () => {
        setBaseUnit(targetUnit);
        setTargetUnit(baseUnit);
    };

    return (
        <div className="container my-3">
            <div className="row justify-content-center">
                <div className="col-md-4 my-3">
                    <label htmlFor="baseUnitSelect">{Capitalize(t(`conversion.from`))}</label>
                    <select className="form-select" id="baseUnitSelect" value={baseUnit} onChange={e => setBaseUnit(e.target.value)}>
                        {Object.keys(unitsData).map(unit => (
                            <option key={unit} value={unit}>{Capitalize(t(`symbols.${category}.${unit}.singular`))}</option>
                        ))}
                    </select>
                </div>
                <div className="col-md-1 d-flex align-items-center justify-content-center">
                    <button className="btn btn-light btn-sm btn-outline-dark w-100 mt-4" onClick={swapUnits}>
                        <FontAwesomeIcon icon={iconMapping["arrows-h"]} size="2x" />
                    </button>
                </div>
                <div className="col-md-4 my-3">
                    <label htmlFor="targetUnitSelect">{Capitalize(t(`conversion.to`))}</label>
                    <select className="form-select" id="targetUnitSelect" value={targetUnit} onChange={e => setTargetUnit(e.target.value)}>
                        {Object.keys(unitsData).map(unit => (
                            <option key={unit} value={unit}>{Capitalize(t(`symbols.${category}.${unit}.singular`))}</option>
                        ))}
                    </select>
                </div>
                <div className="col-md-3 d-flex align-items-end my-3">
                    <button className="btn btn-primary w-100" onClick={handleConversionNavigation}>{Capitalize(t(`conversion.go_to_converter`))}</button>
                </div>
            </div>
        </div>

    );
};

export default UnitPairSelector;
