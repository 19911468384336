import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useBreadcrumb } from './BreadcrumbContext';
import { Capitalize } from '../Util';
import { useTranslation } from '../TranslationContext';

const PersonalFinanceCalculators = () => {
    const { t } = useTranslation();
    const { setBreadcrumb } = useBreadcrumb();

    useEffect(() => {
        setBreadcrumb([
            { text: Capitalize(t(`page.index.name`)), path: '/' },
            { text: Capitalize(t(`conversion.personal_finance`)) }
        ]);
    }, []);

    return (
        <div className="my-3">
            <h2 className="text-center mb-4">{Capitalize(t(`conversion.personal_finance`))}</h2>
            <div className="row justify-content-center">
                <div className="col-md-6 col-lg-4 mb-3">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title text-center">{Capitalize(t('compound_interest_calculator.title'))}</h5>
                            <p className="card-text text-center">{`${t('compound_interest_calculator.description')}`}</p>
                            <div className="d-flex justify-content-center">
                                <Link to={`${t('compound_interest_calculator.title')}`} className="btn btn-primary">
                                    {`${Capitalize(t('conversion.go_to'))} ${t('compound_interest_calculator.title')}`}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PersonalFinanceCalculators;
