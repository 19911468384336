import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { UnitsData } from '../types/unitTypes';
import { useTranslation } from '../TranslationContext';
import { Capitalize, ConvertTwoUnits, ConvertTwoUnitsFull } from '../Util';
import { useBreadcrumb } from './BreadcrumbContext';
import usePageTitle from '../usePageTitle';
import UnitPairSelector from './UnitPairSelector';

interface UnitConvertersProps {
    category: string;
    baseUnit: string;
}

const UnitConverters: React.FC<UnitConvertersProps> = ({ category, baseUnit }) => {
    const [unitsData, setUnitsData] = useState<UnitsData | null>(null);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { setBreadcrumb } = useBreadcrumb();

    usePageTitle(`${Capitalize(t(`conversion.${category}`, ""))} - ${t(`brand.domain`)}`);

    useEffect(() => {
        import(`../data/${category}_units.json`)
            .then((data) => {
                setUnitsData(data.default as UnitsData);
            })
            .catch((error) => {
                console.error("Failed to load units data", error);
                navigate('/404');
            });

    }, [category, navigate, t]);

    useEffect(() => {
        setBreadcrumb([
            { text: Capitalize(t(`page.index.name`)), path: '/' },
            { text: Capitalize(t(`conversion.${category}`)) }
        ]);

    }, [unitsData]);

    if (!unitsData) {
        return <div>Loading...</div>;
    }

    const otherUnits = Object.keys(unitsData).filter(unit => unit !== baseUnit);

    return (
        <div>
            <div className="text-center my-4">
                <UnitPairSelector unitsData={unitsData} category={category} />
            </div>
            <div className="text-center my-4">
                <h2 className="mb-4">{Capitalize(t(`conversion.${category}`))}</h2>
                <div className="row row-cols-1 row-cols-md-2 g-4">
                    {otherUnits.map((unit) => {
                        const conversion = ConvertTwoUnits(unitsData[baseUnit].factor, unitsData[unit].factor)
                        const conversionFull = ConvertTwoUnitsFull(unitsData[baseUnit].factor, unitsData[unit].factor)
                        const baseSymbolTranslations = `symbols.${category}`;
                        const pathOne = `${t(`${baseSymbolTranslations}.${unit}.singular`)}/${t(`${baseSymbolTranslations}.${baseUnit}.singular`)}`;
                        const pathTwo = `${t(`${baseSymbolTranslations}.${baseUnit}.singular`)}/${t(`${baseSymbolTranslations}.${unit}.singular`)}`;
                        const baseSymbol = unitsData[baseUnit].symbol;
                        const unitSymbol = unitsData[unit].symbol;
                        return (
                            <div className="col" key={unit}>
                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="card-title">{Capitalize(t(`${baseSymbolTranslations}.${unit}.plural`))} {t(`${baseSymbolTranslations}.${baseUnit}.to_plural`)}</h5>
                                        <p className="card-text">
                                            1 {t(`${baseSymbolTranslations}.${unit}.singular`)} ({unitSymbol}) =&nbsp;
                                            {conversion.toString()} {t(`${baseSymbolTranslations}.${baseUnit}.partitive`)} ({baseSymbol})&nbsp;<br></br>
                                            (=&nbsp;{conversionFull} {baseSymbol})
                                        </p>
                                        <Link to={pathOne} className="card-link">
                                            {Capitalize(t(`conversion.convert`))} {t(`${baseSymbolTranslations}.${unit}.plural`)} {t(`${baseSymbolTranslations}.${baseUnit}.to_plural`)}
                                        </Link>
                                        <br></br>
                                        <Link to={pathTwo} className="card-link">
                                            {Capitalize(t(`conversion.convert`))} {t(`${baseSymbolTranslations}.${baseUnit}.plural`)} {t(`${baseSymbolTranslations}.${unit}.to_plural`)}
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default UnitConverters;
