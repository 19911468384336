import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from '../TranslationContext';
import { Capitalize } from '../Util';
import './Header.css';
import logo from '../img/logo.png';

const Header: React.FC = () => {
    const { t } = useTranslation();

    return (
        <header className="bg-primary">
            <div className="header-container">
                <nav className="container navbar navbar-expand-lg navbar-dark">
                    <Link className="navbar-brand" to="/">
                        <img src={logo} alt="Logo" className="d-inline-block align-top" />{' '}
                        <div>
                            {Capitalize(t(`brand.title`))}
                            <p className="header-slogan">{t('brand.slogan')}</p>
                        </div>
                    </Link>
                </nav>
            </div>
        </header>
    );
};

export default Header;
