import React, { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import UnitConverters from './components/UnitConverters';
import Categories from './components/Categories';
import SpecificUnitConverter from './components/SpecificUnitConverter';
import NotFoundPage from './components/NotFoundPage';
import { useTranslation } from './TranslationContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import Breadcrumb from './components/Breadcrumb';
import { BreadcrumbProvider } from './components/BreadcrumbContext';
import './App.css';
import './components/GenericCalculator';
import PersonalFinanceCalculators from './components/PersonalFinanceCalculators';
import CompoundInterestCalculator from './components/CompoundInterestCalculator';

interface CategoryData {
  [key: string]: {
    "base_unit": string,
    "type": string,
  };
}

const App: React.FC = () => {
  const [categories, setCategories] = useState<CategoryData>({});
  const [isCategoriesLoaded, setIsCategoriesLoaded] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    import('./data/categories.json')
      .then((data) => {
        setCategories(data.default);
        setIsCategoriesLoaded(true);
      })
      .catch((error) => {
        console.error("Failed to load categories", error);
        setIsCategoriesLoaded(true);
      });
  }, []);

  if (!isCategoriesLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <div id="root">
      <BreadcrumbProvider>
        <Header />
        <Breadcrumb />
        <div className="content">
          <div className="container">
            <Routes>
              <Route path="/" element={<Categories />} />
              {Object.keys(categories).map((category) => {
                const translatedCategory = t(`category.${category}`)
                const baseUnit = categories[category].base_unit;
                const categoryType = categories[category].type;
                if (categoryType === "convert") {
                  return (
                    <Route
                      path={`/${t(`conversion.convert`)}/${translatedCategory}/*`}
                      key={translatedCategory}
                      element={<UnitConverters category={category} baseUnit={baseUnit}
                      />} />
                  );
                } else if (categoryType === "calculate") {
                  switch (category) {
                    case "personal_finance":
                      return (
                        <Route
                          path={`/${t(`conversion.calculate`)}/${translatedCategory}/*`}
                          key={translatedCategory}
                          element={<PersonalFinanceCalculators />}
                        />
                      );
                    default:
                      throw new Error("Invalid category: " + category + " with category type: " + categoryType);
                  }
                } else {
                  throw new Error("Invalid category type: " + categoryType);
                }
              })}
              <Route
                path={`/${t(`conversion.convert`)}/:category/:mainUnit/:targetUnit`}
                element={<SpecificUnitConverter />}
              />
              <Route
                path={`/${t(`conversion.calculate`)}/${t(`category.personal_finance`)}/:calculator`}
                element={<CompoundInterestCalculator />}
              />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </div>
        </div>
        <Footer />
      </BreadcrumbProvider>
    </div >
  );
};

export default App;