import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from '../TranslationContext';
import { Capitalize, ConvertTwoUnits, ConvertTwoUnitsFull, IsScientificNotation, NormalizeNumberString, IsValidNumber } from '../Util';
import { UnitsData } from '../types/unitTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import iconMapping from '../iconMapping';

interface UnitConverterProps {
    category: string;
    fromUnit: string;
    toUnit: string;
    factor: number;
}

const UnitConverter: React.FC<UnitConverterProps> = ({ category, fromUnit, toUnit, factor }) => {
    const [fromValue, setFromValue] = useState('');
    const [toValue, setToValue] = useState('');
    const [resultString, setResultString] = useState('');
    const [lastUpdatedField, setLastUpdatedField] = useState('from');
    const [unitsData, setUnitsData] = useState<UnitsData | null>(null);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const baseSymbolTranslations = `symbols.${category}`;
    const translatedCategory = t(`category.${category}`);
    const viceVersaURL = `/${t(`conversion.convert`)}/${translatedCategory}/${t(`${baseSymbolTranslations}.${toUnit}.singular`)}/${t(`${baseSymbolTranslations}.${fromUnit}.singular`)}`;

    const determinePartitiveTranslation = (value: string, unit: string) => {
        let translation = "";
        const normalizedValue = NormalizeNumberString(value);
        if (parseFloat(normalizedValue) === 1) {
            translation = t(`${baseSymbolTranslations}.${unit}.singular`);
        } else {
            translation = t(`${baseSymbolTranslations}.${unit}.partitive`)
        }
        return translation
    };

    const handleConversion = (
        fromValue: string,
        toValue: string,
        fromUnit: string,
        toUnit: string,
        setValueFn: (value: string) => void,
        invertedConversion: boolean,
    ) => {
        const normalizedValue = NormalizeNumberString(fromValue);

        if (!IsValidNumber(normalizedValue)) {
            setValueFn('');
            setResultString(Capitalize(t(`conversion.invalid_number`)));
            return;
        }

        let result = "";
        if (invertedConversion) {
            result = ConvertTwoUnits("1", factor.toString(), normalizedValue);
        } else {
            result = ConvertTwoUnits(factor.toString(), "1", normalizedValue);
        }
        setValueFn(result.toString());

        let fromString = determinePartitiveTranslation(fromValue, fromUnit);
        let toString = determinePartitiveTranslation(toValue, toUnit);
        let additionalResult = "";

        if (IsScientificNotation(result)) {
            if (unitsData === null) {
                console.error("Units data is null");
                return;
            }
            if (invertedConversion) {
                additionalResult = `(=${ConvertTwoUnitsFull("1", factor.toString(), normalizedValue)} ${unitsData[toUnit].symbol})`;
            } else {
                additionalResult = `(=${ConvertTwoUnitsFull(factor.toString(), "1", normalizedValue)} ${unitsData[toUnit].symbol})`;
            }
        }

        setResultString(`${Capitalize(t(`conversion.result`))}: ${fromValue} ${fromString} = ${result.toString()} ${toString} ${additionalResult}`);
    };

    useEffect(() => {
        import(`../data/${category}_units.json`)
            .then((data) => {
                setUnitsData(data.default as UnitsData);
            })
            .catch((error) => console.error("Failed to load units data", error));
    }, [category]);

    // Effect for converting from 'fromUnit' to 'toUnit'
    useEffect(() => {
        if (lastUpdatedField === 'from' && fromValue !== '') {
            handleConversion(fromValue, toValue, fromUnit, toUnit, setToValue, false);
        }
    }, [fromValue, factor, t, fromUnit, toUnit, lastUpdatedField]);

    // Effect for converting from 'toUnit' to 'fromUnit'
    useEffect(() => {
        if (lastUpdatedField === 'to' && toValue !== '') {
            handleConversion(toValue, fromValue, toUnit, fromUnit, setFromValue, true);
        }
    }, [toValue, factor, t, fromUnit, toUnit, lastUpdatedField]);

    // Function to handle manual conversion
    const handleConvert = () => {
        const result = fromValue ? parseFloat(fromValue) * factor : '';
        setToValue(result.toString());
    };

    const switchUnits = () => {
        navigate(viceVersaURL);
    };

    const handleFromChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFromValue(e.target.value);
        setLastUpdatedField('from');
    };

    const handleToChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setToValue(e.target.value);
        setLastUpdatedField('to');
    };

    const handleClear = () => {
        setFromValue('');
        setToValue('');
        setResultString('');
    };

    if (!unitsData) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <div className="text-center my-3">
                <div className="text-center mb-3">{`${t("page.converter.description")} ${t(`${baseSymbolTranslations}.${fromUnit}.plural`)} (${unitsData[fromUnit].symbol}) ${t(`${baseSymbolTranslations}.${toUnit}.to_plural`)} (${unitsData[toUnit].symbol})`}.{" "}
                    <Link to={viceVersaURL}>
                        {t("page.converter.vice_versa")}
                    </Link>
                </div>
                <div className="row justify-content-center mb-2">
                    <div className="col-12 col-md-5">
                        <div className="form-group mb-2">
                            <div className="input-group">
                                <input
                                    className="form-control"
                                    id="fromValue"
                                    type=""
                                    value={fromValue}
                                    onChange={handleFromChange}
                                    placeholder={`${Capitalize(t(`conversion.enter`))} ${t(`${baseSymbolTranslations}.${fromUnit}.plural`)}`}
                                />
                                <span className="input-group-text">{`${t(`${baseSymbolTranslations}.${fromUnit}.partitive`)}`}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-2 d-flex justify-content-center align-items-center">
                        <button className="btn btn-light btn-sm btn-outline-dark w-75 mb-2" onClick={switchUnits}><FontAwesomeIcon icon={iconMapping["arrows-h"]} size="2x" /></button>
                    </div>
                    <div className="col-12 col-md-5">
                        <div className="form-group mb-2">
                            <div className="input-group">
                                <input
                                    className="form-control"
                                    id="toValue"
                                    type=""
                                    value={toValue}
                                    onChange={handleToChange}
                                    placeholder={`${Capitalize(t(`conversion.enter`))} ${t(`${baseSymbolTranslations}.${toUnit}.plural`)}`}
                                />
                                <span className="input-group-text">{`${t(`${baseSymbolTranslations}.${toUnit}.partitive`)}`}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-12 col-md-5">
                        <button className="btn btn-success w-100 mb-2" onClick={handleConvert}>{`${Capitalize(t(`conversion.convert`))}`}</button>
                    </div>
                    <div className="col-12 col-md-5">
                        <button className="btn btn-warning w-100" onClick={handleClear}>{`${Capitalize(t(`conversion.clear`))}`}</button>
                    </div>
                    <div className="mt-4">
                        {/* Display result here */}
                        {resultString && <h5>{resultString}</h5>}
                    </div>
                </div>
            </div >
        </div>
    );
};

export default UnitConverter;
