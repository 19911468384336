import React from 'react';
import { useTranslation } from '../TranslationContext';
import { Capitalize } from '../Util';

interface UnitInfoSectionProps {
    sysCategory: string;
    sysUnit: string;
}

const UnitInfoSection: React.FC<UnitInfoSectionProps> = ({ sysCategory, sysUnit }) => {
    const { t } = useTranslation();

    const baseSymbolTranslations = `symbols.${sysCategory}`;
    const definition = t(`${baseSymbolTranslations}.${sysUnit}.info.definition`, '');
    const history = t(`${baseSymbolTranslations}.${sysUnit}.info.history`, '');
    const currentUse = t(`${baseSymbolTranslations}.${sysUnit}.info.current_use`, '');

    const hasContent = definition || history || currentUse;
    if (!hasContent) {
        console.warn(`No info content for '${sysUnit}'`)
    }

    return (
        <div className="row">
            {hasContent && <h3>{`${t(`page.converter.what_is`)} ${t(`${baseSymbolTranslations}.${sysUnit}.singular`)}?`}</h3>}
            {definition && <p><b>{Capitalize(t(`page.converter.definition`))}:</b> {definition}</p>}
            {history && <p><b>{Capitalize(t(`page.converter.history`))}:</b> {history}</p>}
            {currentUse && <p><b>{Capitalize(t(`page.converter.current_use`))}:</b> {currentUse}</p>}
        </div>
    );
};

export default UnitInfoSection;
