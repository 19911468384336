import React from 'react';
import { Capitalize } from '../Util';
import { useTranslation } from '../TranslationContext';
import './Footer.css';

const Footer: React.FC = () => {
    const { t } = useTranslation();
    const year = new Date().getFullYear();
    const copyright = t('footer.copyright').replace('{year}', year.toString());

    return (
        <footer className="footer bg-dark text-light py-2 pt-4 mt-5">
            <div className="container text-center">
                <p>{copyright} {Capitalize(t('brand.title'))} </p>
            </div>
        </footer>
    );
};

export default Footer;
