import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from '../TranslationContext';
import { Capitalize } from '../Util';
import { useBreadcrumb } from './BreadcrumbContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import iconMapping from '../iconMapping';
import usePageTitle from '../usePageTitle';

interface CategoryData {
    [key: string]: {
        icon: string;
        type: string;
    };
}

const Categories: React.FC = () => {
    const [categories, setCategories] = useState<CategoryData>({});
    const { t } = useTranslation();
    const { setBreadcrumb } = useBreadcrumb();

    usePageTitle(`${t(`page.index.page_title`)} - ${t(`brand.domain`)}`);

    useEffect(() => {
        // Dynamically import the categories.json
        import('../data/categories.json')
            .then((data) => {
                setCategories(data.default);
            })
            .catch((error) => console.error("Failed to load categories", error));
    }, []);


    useEffect(() => {
        setBreadcrumb([]);
    }, []);

    return (
        <div className="text-center my-3">
            <h1>{Capitalize(t(`page.index.welcome`))}</h1>
            <p>{t(`page.index.welcome_text`)}</p>
            <h2>{Capitalize(t(`page.index.all_calculators`))}</h2>
            <div className="row">
                {Object.keys(categories).map((category) => {
                    const categoryType = categories[category].type;
                    const path = `/${t(`conversion.${categoryType}`)}/${t(`category.${category}`)}`;
                    const categoryData = categories[category];
                    const icon = iconMapping[categoryData.icon];
                    return (
                        <div key={category} className="col-sm-12 col-md-6 col-lg-4 mb-4">
                            <Link to={path} className="text-decoration-none">
                                <div className="card h-100">
                                    <div className="card-body text-center">
                                        <FontAwesomeIcon icon={icon} size="3x" />
                                        <h5 className="card-title mt">{Capitalize(t(`category.${category}`))}</h5>
                                        <div className="btn btn-primary">
                                            {Capitalize(t(`conversion.${category}`))}
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default Categories;
