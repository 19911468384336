import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import {
    faArrowsH,
    faRulerHorizontal,
    faTemperatureHalf,
    faCube,
    faWeightHanging,
    faEuroSign,
} from "@fortawesome/free-solid-svg-icons";

interface IconMapping {
    [key: string]: IconDefinition;
}

const iconMapping: IconMapping = {
    "arrows-h": faArrowsH,
    "ruler-horizontal": faRulerHorizontal,
    "temperature-half": faTemperatureHalf,
    "cube": faCube,
    "weight-hanging": faWeightHanging,
    "euro-sign": faEuroSign
};

export default iconMapping;
