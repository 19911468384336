import React from 'react';
import { Link } from 'react-router-dom';
import './Breadcrumb.css';
import { useBreadcrumb } from './BreadcrumbContext';

const Breadcrumb: React.FC = () => {
    const { items } = useBreadcrumb();

    // If there are no breadcrumb items, don't render the component
    if (items.length === 0) {
        return null;
    }

    return (
        <nav className="breadcrumbs" aria-label="breadcrumb">
            <ol>
                {items.map((item, index) => (
                    <li key={index}>
                        {item.path ? (
                            <>
                                <Link to={item.path}>{item.text}</Link>
                                {index < items.length - 1 && ' / '}
                            </>
                        ) : (
                            item.text
                        )}
                    </li>
                ))}
            </ol>
        </nav>
    );
};

export default Breadcrumb;
